import { assetPrefix } from 'constants/app';

interface KeyFeatureType {
  title: string;
  description: string;
  imageUrl: string;
}

const prefix = `${assetPrefix}/images`;

const keyFeatures: KeyFeatureType[] = [
  {
    title: 'Platform Charting Modern',
    description:
      'Monitor harga saham dari platform charting kelas professional.',
    imageUrl: `${prefix}/charting2.webp`,
  },
  {
    title: 'Data Finansial Yang Lengkap',
    description: 'Dukung analisa Anda dengan data terlengkap dan terpercaya.',
    imageUrl: `${prefix}/financial2.webp`,
  },
  {
    title: 'Screener Terbaik',
    description: 'Cari saham yang layak di investasi dengan mudah.',
    imageUrl: `${prefix}/screener.webp`,
  },
  {
    title: 'PE Standard Deviation Band',
    description:
      'Menunjukkan valuasi saham apakah sedang mahal atau murah secara mudah dan cepat.',
    imageUrl: `${prefix}/p4.webp`,
  },
];

interface ListMediaType {
  name: string;
  url: string;
  image: string;
  height: string;
}

const listMedia: ListMediaType[] = [
  {
    name: 'Techinasia',
    url: 'https://www.techinasia.com/trading-platform-stockbit-gets-funding-from-500-startups',
    image: `${prefix}/techinasia.webp`,
    height: '55px',
  },
  {
    name: 'CNN Indonesia',
    url: 'https://www.cnnindonesia.com/teknologi/20150924142517-185-80773/situs-analisis-saham-stockbit-raih-pendanaan-dari-ideosource',
    image: `${prefix}/CNN_Indonesia.svg`,
    height: '75px',
  },
  {
    name: 'Dailysocial',
    url: 'https://dailysocial.id/post/stockbit-analisis-saham',
    image: `${prefix}/DailySocial.png`,
    height: '50px',
  },
  {
    name: 'Deal Street Asia',
    url: 'https://www.dealstreetasia.com/stories/indonesian-investment-intelligence-platform-stockbit-raises-seed-funding-from-ideosource-13224/',
    image: `${prefix}/dealstreetasia.jpg`,
    height: '75px',
  },
];

const benefits = [
  {
    title: 'Komunitas Saham Terbesar',
    image: `${prefix}/x5.svg`,
    description:
      'Lebih dari 100,000 investor telah bergabung. Diskusi dan dapatkan analisa saham terbaik langsung dari investor yang berpengalaman.',
  },
  {
    title: 'Aman & Terpercaya',
    image: `${prefix}/x6.svg`,
    description:
      'Hindari investasi bodong. Semua transaksi saham di Stockbit melewati perusahaan sekuritas yang telah didaftar dan diawasi oleh OJK.',
  },
];

const companyLogos = [
  [
    `${prefix}/logos-1.svg`,
    `${prefix}/logos-2.svg`,
    `${prefix}/logos-3.svg`,
    `${prefix}/logos-4.svg`,
    `${prefix}/logos-5.svg`,
    `${prefix}/logos-6.svg`,
  ],
  [
    `${prefix}/logos-7.svg`,
    `${prefix}/logos-8.svg`,
    `${prefix}/logos-9.svg`,
    `${prefix}/logos-10.svg`,
    `${prefix}/logos-11.svg`,
    `${prefix}/logos-12.svg`,
  ],
];

const communityBenefits = [
  {
    image: `${prefix}/x1.svg`,
    title: 'Lebih Informatif',
    description:
      'Dapatkan ide investasi yang baik dari hasil diskusi dan analisa saham investor yang berpengalaman.',
  },
  {
    image: `${prefix}/x2.svg`,
    title: 'Lebih Cepat',
    description:
      'Informasi tersebar lebih cepat dengan bantuan komunitas Stockbit. Bersama Stockbit, kamu akan lebih terupdate dengan informasi terkini.',
  },
  {
    image: `${prefix}/x3.svg`,
    title: 'Lebih Seru',
    description:
      'Investasi jadi lebih seru bareng teman dan keluarga kamu. Bagikan strategi kamu untuk saling melengkapi dan nikmati hasilnya bersama.',
  },
];

export { listMedia, keyFeatures, benefits, companyLogos, communityBenefits };
